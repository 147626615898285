define("discourse/plugins/x-discourse-docs-community/discourse/controllers/knowledge-base-index", ["exports", "discourse/plugins/discourse-docs/discourse/controllers/docs-index", "@ember/object", "@ember/application"], function (_exports, _docsIndex, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _docsIndex.default.extend((_obj = {
    returnToList() {
      this.set("selectedTopic", null);
      (0, _application.getOwner)(this).lookup("router:main").transitionTo("knowledge-base");
    }
  }, (_applyDecoratedDescriptor(_obj, "returnToList", [_object.action], Object.getOwnPropertyDescriptor(_obj, "returnToList"), _obj)), _obj));
});